import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { WebTrack } from '@/core/webTrack'
import { IWebTrackRoute } from '@/core/types'
import { useAuthStore } from '@/store/auth'

const routes: Array<RouteRecordRaw> = [
	{
		path: '',
		redirect: '',
		component: () => import('@/components/layouts/main.vue'),
		children: [
			{ path: '', component: () => import('@/components/pages/dashboard/index/index.vue'), meta: { auth: true, webTrack: { moduleName: 'index', functionName: 'openIndex', needTraceId: false } } },
			{ path: '/card/index', component: () => import('@/components/pages/card/index/index.vue'), meta: { auth: true, webTrack: { moduleName: 'card', functionName: 'openIndex', needTraceId: false } } },
			{ path: '/card/page/:id?', component: () => import('@/components/pages/card/page/page.vue'), meta: { auth: true, webTrack: { moduleName: 'card', functionName: 'openPage', needTraceId: true } } },
			{ path: '/card/limitSetting/:id?', component: () => import('@/components/pages/card/limitSetting/page.vue'), meta: { auth: true, webTrack: { moduleName: 'card', functionName: 'openLimitSetting', needTraceId: true } } },
			{ path: '/receipts/index', component: () => import('@/components/pages/receipts/index/index.vue'), meta: { auth: true, webTrack: { moduleName: 'receipts', functionName: 'openIndex', needTraceId: false } } },
			{ path: '/receipts/page/:id?', component: () => import('@/components/pages/receipts/page/page.vue'), meta: { auth: true, webTrack: { moduleName: 'receipts', functionName: 'openPage', needTraceId: false } } }
		]
	},
	{
		path: '',
		component: () => import('@/components/layouts/empty.vue'),
		children: [{ path: '/auth/login', component: () => import('@/components/pages/auth/login/login.vue'), meta: { auth: false, webTrack: { moduleName: 'login', functionName: 'openPage', needTraceId: true } } }]
	}
]

const router = () => {
	const router = createRouter({
		history: createWebHistory(process.env.BASE_URL),
		routes
	})
	const authStore = useAuthStore()
	router.beforeEach((to, from, next) => {
		const requiredAuth = to.matched.some(r => r.meta.auth)
		const token = authStore.token
		if (requiredAuth && !token) next('/auth/login')
		else next()
	})
	router.afterEach((to, from) => {
		if (to.path == from.path) return
		if (to.meta?.webTrack) {
			const webTrackService = new WebTrack()
			const webTrackRoute = to.meta.webTrack as IWebTrackRoute
			webTrackService.create(webTrackRoute)
		}
	})
	return router
}

export default router
